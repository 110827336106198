import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
// import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri = '/common-config/report-heading/detail', orgId, reportTitle, data) => {
    try {
        Store.commit('mutateCommonProperties', {
            loading: true
        })
        if (i18n.locale === 'bn') {
            pdfMake.vfs = pdfFontsBn.pdfMake.vfs
        } else {
            pdfMake.vfs = pdfFontsEn.pdfMake.vfs
        }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center' })
        pdfContent.push({ text: '', style: 'fertilizer' })

        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['*', '*', '*', '*'],
                body: [
                    [
                        { text: data.$t('globalTrans.division'), style: 'th' },
                        { text: data.$i18n.locale === 'bn' ? data.formData.division_name_bn : data.formData.division_name, style: 'td' },
                        { text: data.$t('globalTrans.district'), style: 'th' },
                        { text: (i18n.locale === 'bn') ? data.formData.district_name_bn : data.formData.district_name, style: 'td' }
                    ],
                    [
                        { text: data.$t('org_pro_upazilla.upazilla'), style: 'th' },
                        { text: data.$i18n.locale === 'bn' ? data.formData.upazilla_name_bn : data.formData.upazilla_name, style: 'td' },
                        { text: data.$t('dae_subsidy.fiscal_year'), style: 'th' },
                        { text: (i18n.locale === 'bn') ? data.formData.fiscal_year_bn : data.formData.fiscal_year, style: 'td' }
                    ],
                    [
                        { text: data.$t('dae_subsidy.circular'), style: 'th' },
                        { text: data.$i18n.locale === 'bn' ? data.formData.circular_bn : data.formData.circular, style: 'td', colSpan: 3 },
                        { },
                        { }
                    ]
                ]
            }
        })
        pdfContent.push({ text: data.$t('bsri_demandDIS.farmers_list'), style: 'header2', alignment: 'center' })
        const expenditureInfoRow = [
            [
                { text: data.$t('globalTrans.sl_no'), style: 'th' },
                { text: data.$t('globalTrans.name'), style: 'th' },
                { text: data.$t('financialAid.farmer_father_name'), style: 'th' },
                { text: data.$t('dae_subsidy.nid'), style: 'th' },
                { text: data.$t('dae_subsidy.mobile_no'), style: 'th' },
                { text: data.$t('common_config.account_type'), style: 'th' },
                { text: data.$t('common_config.account_no'), style: 'th' },
                { text: data.$t('bsri_demandDIS.bank'), style: 'th' },
                { text: data.$t('bsri_demandDIS.branch'), style: 'th' },
                { text: data.$t('dae_subsidy.routing'), style: 'th' },
                { text: data.$t('financialAid.cash_tk'), style: 'th' }
            ]
        ]
        data.formData.applicant_details.forEach((element, index) => {
            expenditureInfoRow.push([
                { text: data.$n(index + 1), style: 'td' },
                { text: data.$i18n.locale === 'bn' ? element.farmer_name_bn : element.farmer_name, style: 'td' },
                { text: data.$i18n.locale === 'bn' ? element.father_name_bn : element.father_name, style: 'td' },
                { text: data.$n(element.nid_no, { useGrouping: false }), style: 'td' },
                { text: (data.$i18n.locale === 'bn' ? '০' : '0') + data.$n(element.mobile_no, { useGrouping: false }), style: 'td' },
                { text: element.account_type_name, style: 'td' },
                { text: element.farmer_data.ac_category_id !== 1 ? data.$n(element.farmer_data.account_no, { useGrouping: false }) : (element.farmer_data.wallet_no) ? (data.$i18n.locale === 'bn' ? '০' : '0') + data.$n(element.farmer_data.wallet_no, { useGrouping: false }) : ' ', style: 'td' },
                { text: element.farmer_data.ac_category_id === 1 ? element.ac_type_name : element.bank_name, style: 'td' },
                { text: element.branch_name, style: 'td' },
                { text: element.routing_no, style: 'td' },
                { text: data.$n(data.allocationAmount, { useGrouping: false }), style: 'td' }
            ])
        })
        expenditureInfoRow.push([
            { text: data.$t('globalTrans.total'), style: 'td', colSpan: 10, alignment: 'right' },
            { },
            { },
            { },
            { },
            { },
            { },
            { },
            { },
            { },
            { text: data.$n(data.grandTotal, { useGrouping: false }), style: 'td' }
        ])
        pdfContent.push({
            table: {
                headerRows: 1,
                widths: ['5%', '10%', '10%', '9%', '11%', '10%', '10%', '8%', '9%', '10%', '9%'],
                body: expenditureInfoRow
            }
        })
        var docDefinition = {
            content: pdfContent,
            pageSize: 'A4',
            pageOrientation: 'landscape',
            // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
            styles: {
                th: {
                    fillColor: '',
                    fontSize: 10,
                    bold: true,
                    margin: [3, 3, 3, 3]
                },
                td: {
                    fontSize: 10,
                    margin: [3, 3, 3, 3]
                },
                header: {
                    fontSize: 12,
                    bold: true,
                    margin: [0, 0, 0, 4]
                },
                header2: {
                    fontSize: 15,
                    bold: true,
                    margin: [15, 15, 25, 10]
                },
                hh: {
                    fontSize: 17,
                    bold: true,
                    margin: [25, 15, 25, 10]
                },
                header11: {
                    fontSize: 13,
                    bold: true,
                    margin: [15, 10, 10, 10]
                },
                transport: {
                    fontSize: 10,
                    margin: [10, 10, 0, 20]
                },
                headerPort1: {
                    fontSize: 10,
                    margin: [0, 20, 0, 0]
                },
                headerPort: {
                    fontSize: 10,
                    margin: [0, 4, 0, 15]
                },
                header3: {
                    fontSize: 9,
                    margin: [0, 0, 0, 4]
                },
                tableSubHead: {
                    margin: [0, 5, 0, 15]
                },
                krishi: {
                    margin: [0, -15, 0, 15],
                    alignment: 'center'
                }
            }
        }
        pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
        if (error) {}
    }
    Store.commit('mutateCommonProperties', {
        loading: false
    })
}
export default {
    exportPdfDetails
}
